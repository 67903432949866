// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  envName: 'dev',
  // apiUrl: 'http://test-chutti.cloudnowtech.net/core/api/v1/',
  //apiUrl: 'https://qa-chutti.cloudnowtech.net/core/api/v1/',
   apiUrl: 'https://app.chutti.work/core/api/v1/',
  // apiUrl: 'https://demo.app.chutti.work/core/api/v1/',
}
/*
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
